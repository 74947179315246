import * as React from "react"
import {GeneralContext} from "../../core/general.context"
import {CartContext} from "../../core/cart.context"
import {LabelContext} from "../../core/label.context"
import {CheckoutContext} from "../../core/checkout.context"
import {Authentication} from "../login/authentication"
import {Box} from "../box/box.component"
import {EAddressType, EShippingType, IAddAddressFormMethods} from "../../common/common.models"
import {
    ADYEN_ENABLEDPAYMENTMETHODS,
    BRX_ASSETS_URL,
    BRX_LOCALE,
    CHECKOUT_PAYMENT_LOGOS,
    IS_GIFT_CARD_ACTIVE,
    PAYFAST_BASE_URL,
    PAYFAST_CANCEL_URL,
    PAYFAST_MERCHANT_ID,
    PAYFAST_MERCHANT_KEY,
    PAYFAST_NOTIFY_URL,
    PAYFAST_RETURN_URL,
    PAYMENT_METHODS,
    PROJECT_COUNTRY,
    PROJECT_KEY,
    PROJECT_LANGUAGE,
    PROJECT_SETTINGS
} from "../../core/APIUtils"
import {ESelectedAddress, EUserDataAddressesIdentifier, IAddressList} from "./checkout.models"
import {Button} from "../button/button.component"
import {AddAddress} from "../elements/address/address.component"
import {ITotalPrice} from "../../common/cart.models"
import {getBrowserInfo, parsePrice, redirectTo} from "../../common/constants"
import {Property} from "../elements/property/property.component"
import {InputElement} from "../input/input.component"
import * as yup from "yup"
import {SubmitHandler, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import SelectMultiOptions from "../elements/select/SelectMultiOptions"
import {YounitedPay} from "../younitedPay/younitedPay.component"
import styled from "styled-components"
import {UserContext} from "../../core/user.context"
import {AWSlogger} from "../../core/rest.context"

const PaymentMethodDiv = styled.div`
    align-items: center;
    margin-bottom: 1rem;
`;

const RadioInput = styled.input`
    margin-right: 10px;
`;

export const CheckoutStep1 = (props: any) => {
    const addAddressFormRef_shipping = React.useRef<IAddAddressFormMethods>(null)
    const addAddressFormRef_billing = React.useRef<IAddAddressFormMethods>(null)
    const authenticationFormRef = React.useRef<any>(null)
    const { cart, shippingMethods, userData } = React.useContext(GeneralContext)
    const {
        resetCartForCheckout,
        setConsentThrdParty,
        processingOrder,
        setNlConsent,
        setSelectedShippingMethod
    } = React.useContext(CartContext)
    const { t } = React.useContext(LabelContext)
    const {
        shippingType, setShippingType,
        setTempAddresses,
        privacy, setPrivacy,
        consent, setConsent,
        externalWarrantyCheck, setEternalWarrantyCheck,
        nlConsentValue, setNlConsentValue,
        commercial, setCommercial, focusPrivacy,
        consent3Party, setApplepayDisabled,
        unmountAdyenComponents,hasExternalWarrantyService,isMandatoryFieldsFlagged,
        addAddressFormVisibility, setAddAddressFormVisibility,
        useShippingAddressAsBilling, makeSignUp,
        numberAddressAvailable, selectedAddress, isAddressEdit,
        setShowListAddress, setSelectedAddress, setUseShippingAddressAsBilling,
        setIsAddressEdit
    } = React.useContext(CheckoutContext)
    const {setCheckoutStep} = React.useContext(CheckoutContext)
    const {signupFromCheckout, loginAsGuestFromCheckout, addMultipleAddress} = React.useContext(UserContext)

    const goToStep2 = async () => {
        if(!isMandatoryFieldsFlagged()){
            return
        }

        const { shippingAddress, billingAddress } = cart
        let currentShippingAddress: any = null
        let currentBillingAddress: any = null
        let shippingFormOpened: boolean = false
        let billingFormOpened: boolean = false

        //form shipping address aperto e validato?
        const isAddAddressShippingFormValid = await addAddressFormRef_shipping.current?.addAddressFormValidate()
        if(isAddAddressShippingFormValid){
            currentShippingAddress = await addAddressFormRef_shipping.current?.addAddressFormGetData()
            shippingFormOpened = true
        } else {
            if(isAddAddressShippingFormValid === false){
                //form shipping address aperto ma non valorizzato
                return
            }else{
                if(shippingAddress?.custom?.fields.internalType === "placeholder"){
                    //non e stato selezionato un billing
                    return
                } else {
                    currentShippingAddress = shippingAddress
                }
            }
        }

        //form billing address aperto e validato?
        const isAddAddressBillingFormValid = await addAddressFormRef_billing.current?.addAddressFormValidate()
        if(isAddAddressBillingFormValid){
            currentBillingAddress = await addAddressFormRef_billing.current?.addAddressFormGetData()
            billingFormOpened = true
        } else {
            if(isAddAddressBillingFormValid === false){
                //form billing address aperto ma non valorizzato
                return
            } else {
                if(billingAddress?.custom?.fields.internalType === "placeholder"){
                    //non é statao selezionato uno shipping
                    return
                }else {
                    currentBillingAddress = billingAddress
                }
            }
        }

        const isAuthenticationFormValid = await authenticationFormRef.current?.authenticationFormValidate()

        if((isAuthenticationFormValid || userData) && currentShippingAddress &&  (currentBillingAddress || useShippingAddressAsBilling)){
            const authenticationFormData = await authenticationFormRef.current?.authenticationFormGetData()

            try{
                if(userData){
                    if(shippingAddress && shippingAddress?.custom?.fields.internalType !== "placeholder" && billingAddress && !shippingFormOpened && !billingFormOpened){
                        setCheckoutStep(2)
                    }else{
                        const addressToUpdate: { address: any; type: EAddressType } [] = []
                        addressToUpdate.push(
                            {
                                address: currentShippingAddress,
                                type: EAddressType.Shipping
                            }
                        )
                        if(useShippingAddressAsBilling){
                            addressToUpdate.push(
                                {
                                    address: currentShippingAddress,
                                    type: EAddressType.Billing
                                }
                            )
                        }else{
                            addressToUpdate.push(
                                {
                                    address: currentBillingAddress,
                                    type: EAddressType.Billing
                                }
                            )
                        }
                        addMultipleAddress(addressToUpdate, true, shippingType).subscribe(
                            () => {
                                setCheckoutStep(2)
                            },
                            (err: any) => {
                                console.error('Errore durante l inserimento degli indirizzi:', err)
                            }
                        )
                    }
                }else{
                    if(makeSignUp){
                        signupFromCheckout(
                            authenticationFormData.email,
                            authenticationFormData.password,
                            nlConsentValue,
                            authenticationFormData.profiling == 1,
                            currentShippingAddress,
                            currentBillingAddress,
                            useShippingAddressAsBilling,
                            shippingType
                        ).subscribe(
                            () => {
                                if(shippingFormOpened){
                                    setAddAddressFormVisibility(EAddressType.Shipping, false)
                                    addAddressFormRef_shipping.current?.addAddressFormReset()
                                }
                                if (billingFormOpened) {
                                    setAddAddressFormVisibility(EAddressType.Billing, false)
                                    addAddressFormRef_billing.current?.addAddressFormReset()
                                }
                                authenticationFormRef.current?.authenticationFormReset()
                                setCheckoutStep(2)
                            },
                            (err: any) => {
                                console.error('Errore durante la registrazione:', err)
                                AWSlogger({
                                    type: "error",
                                    action: "signupFromCheckout",
                                    email: authenticationFormData.email,
                                    shippingAddress: currentShippingAddress,
                                    billingAddress: currentBillingAddress,
                                    shippingType: shippingType,
                                    browserInfo: getBrowserInfo(),
                                    errorMessage: err
                                })
                            }
                        );


                    } else {
                        loginAsGuestFromCheckout(authenticationFormData.email, currentShippingAddress, currentBillingAddress, useShippingAddressAsBilling, shippingType, false)
                            .subscribe(
                                () => {
                                    if(shippingFormOpened){
                                        setAddAddressFormVisibility(EAddressType.Shipping, false)
                                        addAddressFormRef_shipping.current?.addAddressFormReset()
                                    }
                                    if (billingFormOpened) {
                                        setAddAddressFormVisibility(EAddressType.Billing, false)
                                        addAddressFormRef_billing.current?.addAddressFormReset()
                                    }
                                    authenticationFormRef.current?.authenticationFormReset()
                                    setCheckoutStep(2)
                                },
                                (err: any) => {
                                    console.error('Errore durante il login:', err)
                                    AWSlogger({
                                        type: "error",
                                        action: "loginAsGuestFromCheckout",
                                        email: authenticationFormData.email,
                                        shippingAddress: currentShippingAddress,
                                        billingAddress: currentBillingAddress,
                                        shippingType: shippingType,
                                        browserInfo: getBrowserInfo(),
                                        errorMessage: err
                                    })
                                }
                            );
                    }
                }
            }catch (e){
                AWSlogger({
                    type: "error",
                    action: "goToStep2",
                    browserInfo: getBrowserInfo(),
                    errorMessage: e
                })
            }
        } else {
            return
        }

    }

    const step1Validation = () => {
        goToStep2().then(() => {
            const element = document.querySelector(".message--alert") || document.querySelector(".focus");
            if (element) {          
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }else{
                window.scrollTo({top: 0, left: 0, behavior: "smooth"})
            }
        })
    }

    const handleAddAddressSave = async (type: EAddressType) => {
        const formRef = type === EAddressType.Shipping
            ? addAddressFormRef_shipping.current
            : addAddressFormRef_billing.current

        if (!formRef) return

        const isFormValid = await formRef.addAddressFormValidate()
        if (!isFormValid) return

        const formData = await formRef.addAddressFormGetData()

        if (!userData) return

        const addressToUpdate: { address: any; type: EAddressType }[] = [
            { address: formData, type }
        ];

        if (type === EAddressType.Shipping && useShippingAddressAsBilling) {
            addressToUpdate.push({
                address: formData,
                type: EAddressType.Billing
            })
        }

        addMultipleAddress(addressToUpdate, true, shippingType).subscribe(
            () => {
                // Resetta il modulo dopo il salvataggio
                formRef.addAddressFormReset();
                setAddAddressFormVisibility(type, false)
                setShowListAddress(type, false)
                setIsAddressEdit(type, false)
            },
            (err: any) => {
                console.error(`Errore durante il salvataggio del ${type} address:`, err)
                AWSlogger({
                    type: "error",
                    action: "addMultipleAddress",
                    email: addressToUpdate,
                    shippingType: shippingType,
                    browserInfo: getBrowserInfo(),
                    errorMessage: err
                })
            }
        )
    }

    const isPickUpAvailable = shippingMethods?.find((store: any) => store.custom?.fields["shipping-type-list"] === EShippingType.Pickup)
    const isSubscribed = userData?.custom?.fields?.subscribed

    return (
        <>
        {!userData &&
            <Authentication ref={authenticationFormRef}/>
        }
        <div style={{position: "relative"}} className={`row no-gutters ${processingOrder && "processingOrderStyle"}`}>
            <div className="checkout-column-main w-100">
                <div className="">
                    {/* Seleziona Store o Consega a casa */}
                    {isPickUpAvailable && (
                        <Box className="secondary compact unbordered mb-0">
                            <h4 className="mb-0">{t("ecommerce.checkout.select-delivery-method")}</h4>

                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="radio-item">
                                        <input
                                            id="home-delivery"
                                            type="radio"
                                            name="deliveryMethod"
                                            className=""
                                            checked={shippingType === EShippingType.Home}
                                            onChange={() => {
                                                setShippingType(EShippingType.Home)
                                                resetCartForCheckout(null, true)
                                                setSelectedShippingMethod(null)
                                                setShowListAddress(EAddressType.Shipping, true)
                                                setSelectedAddress(EAddressType.Shipping, null)
                                                setUseShippingAddressAsBilling(true)
                                                unmountAdyenComponents()
                                                setApplepayDisabled(false);
                                                const younitedComponent = document.getElementById("younited-close")
                                                if (younitedComponent) {
                                                    younitedComponent.click()
                                                }
                                            }}
                                        />
                                        <label htmlFor="home-delivery" className="">
                                            {t("ecommerce.checkout.delivery-to-home-title")}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="radio-item">
                                        <input
                                            id="pickup-in-store"
                                            type="radio"
                                            name="deliveryMethod"
                                            className="checkout-radio mr-2"
                                            checked={shippingType === EShippingType.Pickup}
                                            onChange={() => {
                                                setShippingType(EShippingType.Pickup)
                                                resetCartForCheckout(null, true)
                                                setShowListAddress(EAddressType.Shipping, true)
                                                setSelectedAddress(EAddressType.Shipping, null)
                                                setUseShippingAddressAsBilling(false)
                                                unmountAdyenComponents()
                                                setApplepayDisabled(false)
                                                const younitedComponent = document.getElementById("younited-close")
                                                if (younitedComponent) {
                                                    younitedComponent.click()
                                                }
                                            }}
                                        />
                                        <label htmlFor="pickup-in-store">
                                            {t("ecommerce.checkout.pickup-in-store-title")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    )}
                </div>
            </div>
        </div>


        {userData &&
            <div>
                <h3>{t("ecommerce.checkout.address-section-title")}</h3>
            </div>
        }

        {/* Lista indirizzi di cosnegna  */}
        <AddressList
            className={""}
            type={EAddressType.Shipping}
            shippingType={shippingType}
        />

        {addAddressFormVisibility.shipping &&
            <div className="component">
                <Box className={`secondary compact`}>
                    <div id="containerAddAddress">
                        <AddAddress ref={addAddressFormRef_shipping} dataEdit={isAddressEdit.shipping ? selectedAddress.shipping : null} type={EAddressType.Shipping} callbackData={setTempAddresses}/>
                        {userData && numberAddressAvailable.shipping > 0 &&
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-4 offset-md-1">
                                        <Button onClick={() => handleAddAddressSave(EAddressType.Shipping)} className="primary actionForm" label={t("ecommerce.common.save")}/>
                                    </div>
                                    <div className="col-md-4 offset-md-2">
                                        <Button
                                            className="secondary actionForm "
                                            label={t("ecommerce.common.cancel")}
                                            type="button"
                                            onClick={() => setAddAddressFormVisibility(EAddressType.Shipping, false)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Box>
            </div>
        }

        {/* Lista indirizzi di fatturazione  */}
        <>
            <AddressList type={EAddressType.Billing} />
            {addAddressFormVisibility.billing &&
                <div className="component">
                    <Box className={`secondary compact`}>
                        <div id="containerAddAddress">
                            <AddAddress ref={addAddressFormRef_billing} dataEdit={isAddressEdit.billing ? selectedAddress.billing : undefined} type={EAddressType.Billing} callbackData={setTempAddresses}/>
                            {userData && numberAddressAvailable.billing > 0 &&
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-4 offset-md-1">
                                            <Button onClick={() => handleAddAddressSave(EAddressType.Billing)} className="primary actionForm" label={t("ecommerce.common.save")}/>
                                        </div>
                                        <div className="col-md-4 offset-md-2">
                                            <Button
                                                className="secondary actionForm "
                                                label={t("ecommerce.common.cancel")}
                                                type="button"
                                                onClick={() => setAddAddressFormVisibility(EAddressType.Billing, false)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Box>
                </div>
            }
        </>

        <div className="component">
            <Box className="secondary compact">
                {!userData && (
                    <div className="component">
                        <ul>
                            {PROJECT_COUNTRY !== "PL" &&
                                <li key="privacy" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                    <div className="d-flex align-items-center mb-1">
                                        <input
                                            type="checkbox"
                                            checked={privacy}
                                            value="1"
                                            name="privacy"
                                            id="privacy"
                                            onClick={(event: any) => {
                                                setPrivacy(event.target?.checked)
                                            }}
                                        />
                                        <label
                                            htmlFor="privacy"
                                            dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.privacy-check-description") }}
                                        />
                                        <span className="pr-1">*</span>
                                    </div>
                                </li>
                            }
                            <li key="commercial" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                <div className="d-flex align-items-center mb-1">
                                    <input
                                        type="checkbox"
                                        checked={commercial}
                                        value="1"
                                        name="commercial"
                                        id="commercial"
                                        onClick={(event: any) => {
                                            setCommercial(event.target?.checked)
                                        }}
                                    />
                                    <label htmlFor="commercial">
                                        <span dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.commercial-check-description")}}></span>
                                        <span className="pr-1">*</span>
                                    </label>
                                </div>
                            </li>
                            {hasExternalWarrantyService &&
                                <li key="external-warranty-service" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                    <div className="d-flex align-items-center mb-1">
                                        <input
                                            type="checkbox"
                                            checked={externalWarrantyCheck}
                                            value="1"
                                            name="external-warranty-service"
                                            id="external-warranty-service"
                                            onClick={(event: any) => {
                                                setEternalWarrantyCheck(event.target?.checked)
                                            }}
                                        />
                                        <label
                                            htmlFor="external-warranty-service"
                                            dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.external-warranty-check-description")}}
                                        />
                                        <span className="pr-1">*</span>
                                    </div>
                                </li>
                            }
                            <li key="nl-consent" className={`checkboxButton`}>
                                <div className="d-flex align-items-center mb-1">
                                    <input
                                        type="checkbox"
                                        checked={nlConsentValue}
                                        value="1"
                                        name="nl-consent"
                                        id="nl-consent"
                                        onClick={(event: any) => { setNlConsent(!nlConsentValue); setNlConsentValue(event.target?.checked) }}
                                    />
                                    <label
                                        htmlFor="nl-consent"
                                        dangerouslySetInnerHTML={{__html: t("ecommerce.signup.newsletter-flag")}}
                                    />
                                </div>
                            </li>
                            {consent3Party &&
                                <li key="consent-3-party" className={`checkboxButton`}>
                                    <div className="d-flex align-items-center mb-1">
                                        <input
                                            type="checkbox"
                                            checked={consent}
                                            value="1"
                                            name="consent-3-party"
                                            id="consent-3-party"
                                            onClick={(event: any) => {
                                                setConsent(event.target?.checked)
                                                setConsentThrdParty(event.target?.checked)
                                            }}
                                        />
                                        <label
                                            htmlFor="consent-3-party"
                                            dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.consent-3-party-check-description")}}
                                        />
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                )}

                {userData && (
                    <div className="component">
                        <ul>
                            <li key="commercial" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                <div className="d-flex align-items-center mb-1">
                                    <input
                                        type="checkbox"
                                        value="1"
                                        checked={commercial}
                                        name="commercial"
                                        id="commercial"
                                        onClick={(event: any) => {
                                            setCommercial(event.target?.checked)
                                        }}
                                    />
                                    <label
                                        htmlFor="commercial"
                                        dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.commercial-check-description")}}
                                    />
                                    <span className="pr-1">*</span>
                                </div>
                            </li>
                            {hasExternalWarrantyService &&
                                <li key="external-warranty-service"
                                    className={`checkboxButton ${focusPrivacy && "focus"}`}>
                                    <div className="d-flex align-items-center mb-1">
                                        <input
                                            type="checkbox"
                                            checked={externalWarrantyCheck}
                                            value="1"
                                            name="external-warranty-service"
                                            id="external-warranty-service"
                                            onClick={(event: any) => {
                                                setEternalWarrantyCheck(event.target?.checked)
                                            }}
                                        />
                                        <label
                                            htmlFor="external-warranty-service"
                                            dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.external-warranty-check-description")}}
                                        />
                                        <span className="pr-1">*</span>
                                    </div>
                                </li>
                            }
                            {!isSubscribed &&
                                <li key="nl-consent" className={`checkboxButton`}>
                                    <div className="d-flex align-items-center mb-1">
                                        <input
                                            type="checkbox"
                                            checked={nlConsentValue}
                                            value="1"
                                            name="nl-consent"
                                            id="nl-consent"
                                            onClick={(event: any) => {
                                                setNlConsent(!nlConsentValue);
                                                setNlConsentValue(event.target?.checked)
                                            }}
                                        />
                                        <label
                                            htmlFor="nl-consent"
                                            dangerouslySetInnerHTML={{__html: t("ecommerce.signup.newsletter-flag")}}
                                        />
                                    </div>
                                </li>
                            }
                            {consent3Party &&
                                <li key="consent-3-party" className={`checkboxButton`}>
                                    <div className="d-flex align-items-center mb-1">
                                        <input
                                            type="checkbox"
                                            checked={consent}
                                            value="1"
                                            name="consent-3-party"
                                            id="consent-3-party"
                                            onClick={(event: any) => {
                                                setConsent(event.target?.checked)
                                                setConsentThrdParty(event.target?.checked)
                                            }}
                                        />
                                        <label
                                            htmlFor="consent-3-party"
                                            dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.consent-3-party-check-description")}}
                                        />
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                )}
            </Box>
        </div>

        {/*logged + indirizzi + form aperto = gotostep2 disabilitato (perche sono presenti salva e cancella per i singoli form degli indirizzi)
        logged + no indirizzi + form aperto = gotostep2 abilitato
        guest + indirizzi + form aperto = gotostep2 abilitato
        guest + no indirizzi + form aperto = gotostep2 abilitato
        */}
        <button className="btn-checkout" onClick={step1Validation} disabled={
            userData &&
            (
                (addAddressFormVisibility.shipping && numberAddressAvailable.shipping > 0) ||
                (addAddressFormVisibility.billing && numberAddressAvailable.billing > 0) ||
                (!selectedAddress.shipping && numberAddressAvailable.shipping > 0) ||
                (!selectedAddress.billing && numberAddressAvailable.billing > 0)
            )
        }>{t("ecommerce.checkout.go-to-step-2")}</button>
        </>
    )
}

export const CheckoutStep2 = (props: any) => {
    const { cart, shippingMethods, payU, younitedUri, giftCards,  buyBox, payFastSignature } = React.useContext(GeneralContext)
    const {
        checkIsCartChange,
        isCartChanged,
        setNoteOnCart,
        setShippingMethod,
        processingOrder,
        populateGiftCardRecipientInfo,
        differenceBy,
        setApplyGiftCardToCart,
        setRemoveGiftCardFromCart,
        selectedGiftcards,
        setSelectedGiftcards,
        setRemoveAllGiftCardFromCart,
        loadGiftCards,
    } = React.useContext(CartContext)
    const { t } = React.useContext(LabelContext)
    const {
        shippingType, clickedPay,
        setClickedPay, clickedPayWithPaypal,
        paypalError, applepayEnabled,
        lineItemWithNoAvailability,
        showPaypal, loadingPaypal,
        enabledGiftCardEditButton, setEnabledGiftCardEditButton,
        giftcardInsideCard, setGiftcardInsideCart,
        hasGiftCards, setHasGiftCards,
        showGiftCardSelect, setShowGiftCardSelect,
        loadIdealForm, unmountAdyenComponents,
        loadAdyenForm, loadApplePayForm,
        loadSepaForm, loadPaypalForm,
        loadBancontactForm, loadPaypalDirectForm,
        payWithBankTransfer,closeOrderWithGiftCard,
        payLaterWithPayByLink, loadKlarnaForm,
        redirectToPayU, redirectToYounitedPay,
        redirectToBuyBox, redirectToPayFast,
        setCheckoutStep, setIsAddressEdit,
        setUseShippingAddressAsBilling
    } = React.useContext(CheckoutContext)

    let requiredError = t("ecommerce.form-messagge.required");
    let invalidEmail = t("ecommerce.form-messagge.email-invalid")
    let confirmEmailNotMatchError = t("ecommerce.form-messagge.email-must-match")
    const giftCardItemSchema = yup.object().shape({
        value: yup.object().shape({
            name: yup.string().required(requiredError),
            surname: yup.string().required(requiredError),
            email: yup.string().email(invalidEmail).required(requiredError),
            confirmEmail: yup.string().email(invalidEmail).required(requiredError).test(
                'email-match',
                confirmEmailNotMatchError,
                function(value) {
                    const { email } = this.parent;
                    return email === value;
                }
            ),
        })
    });

    const giftCardSchema = yup.object().shape({
        "gift-card": yup.lazy(objectShape => {
            const obj: any = {};
            Object.keys(objectShape).forEach(key => {
                obj[key] = yup.array().of(giftCardItemSchema);
            });
            return yup.object().shape(obj);
        })
    });

    //to debuggin form console.log(watch())
    const {register, formState: {errors}, handleSubmit, reset, watch } = useForm<any>({
        resolver: yupResolver(giftCardSchema),
        mode: "onSubmit",
        defaultValues: {
            "gift-card": {}
        },
    });

    let options: any[] = []
    if (giftCards) {
        giftCards.forEach((giftCard: any) => {
            options.push({ value: giftCard?.serialNumber.toString(), label: giftCard?.giftCardName, residual: giftCard?.residualValue })
        })
    }

    const handleGiftCardSelectOnChange = (values: any) => {
        const diffValue = differenceBy(values, selectedGiftcards)
        const wasThereBefore = selectedGiftcards.some((x: any) => x?.value === diffValue?.value)
        //capisco se sto rimuovendo o aggiungeno una giftcard a carrello
        if (wasThereBefore) {
            const removeGiftcard = cart.customLineItems.find((item: any) => (item.slug.split('gift-card-')[1] === diffValue?.value));
            setRemoveGiftCardFromCart(removeGiftcard)
        } else {
            setApplyGiftCardToCart(diffValue)
        }
        //smonto i componenti di adyen e younited se seleziono una giftcard
        unmountAdyenComponents()
        const younitedComponent = document.getElementById("younited-close");
        if(younitedComponent){
            younitedComponent.click();
        }
    };

    const onSubmit: SubmitHandler<any> = (data) => {
        populateGiftCardRecipientInfo(data)
    }


    const getSelectedAddress = (type: EAddressType) => {
        let address = cart[ESelectedAddress[type]]
        let selectedAddress = ""
        if (address && address?.custom?.fields?.internalType !== "placeholder") {
            selectedAddress =
            type === "billing"
                ? address.custom?.fields?.fiscalcode
                ? address.custom?.fields?.fiscalcode
                : address.company
            ? address.company
                : ""
            : ""
            selectedAddress =
            selectedAddress +
            " " +
            address.firstName +
            " " +
            (address.lastName ? address.lastName : "") +
            ", " +
            address.streetName +
            " " +
            (address.streetNumber !== undefined ? address.streetNumber : "") +
            ", " +
            address.postalCode +
            " " +
            address.city
        }
        return selectedAddress
    }
    const selectedShippingAddress = getSelectedAddress(EAddressType.Shipping);
    const selectedBillingAddress = getSelectedAddress(EAddressType.Billing);
    // Titolo della sezione indirizzi
    let shippingAddressSectionTitle: string = shippingType === EShippingType.Home ? t("ecommerce.checkout.home-address-title") : t("ecommerce.checkout.select-store-title")
    let billingAddressSectionTitle: string = t("ecommerce.checkout.billing-address-title")


    React.useEffect(() => {
        if (cart.customLineItems.length <= 0) {
            setSelectedGiftcards([])
        }

        if(giftCards != null && giftCards.length > 0){
            setHasGiftCards(true)
        }

        const allGiftCardRecipientsFilled = cart.lineItems.every((giftCard) => {
            if (!giftCard?.productType?.obj?.key || giftCard?.productType?.obj?.key !== "GIFTCARD") {
                return true;
            }

            const { quantity } = giftCard;

            if (giftCard?.custom?.fields['recipients-giftcard']) {
                let dataRecipient: any;
                try {
                    dataRecipient = JSON.parse(giftCard?.custom?.fields['recipients-giftcard']);
                } catch (e) {
                    return false;
                }

                return [...Array(quantity)].every((_, i) => {
                    return dataRecipient[i]?.value.name &&
                        dataRecipient[i]?.value.surname &&
                        dataRecipient[i]?.value.email;
                });
            }
        });
        setEnabledGiftCardEditButton(allGiftCardRecipientsFilled)
    }, [cart, giftCards])

    React.useEffect(() => {
        const data: any = { "gift-card": {}}
        checkIsCartChange(cart)

        //carica le giftcard
        loadGiftCards(true);

        //controlla se ci sono giftcard aggiunte a carrello
        const hasActiveGiftCardsInCart = cart.customLineItems.length > 0
        //mostra la select se ci sono giftcard attive
        if(hasActiveGiftCardsInCart){
            setShowGiftCardSelect(true)
        }

        const selectedGiftCard: any = [];
        cart.customLineItems.map((item: any) => {
            selectedGiftCard.push({ value: item.slug.split('gift-card-')[1], label: item.name[Object.keys(item.name)[0]], residual: Math.abs(item.money.centAmount) })
        });
        setSelectedGiftcards(selectedGiftCard)

        const selectedGiftCards = cart.customLineItems
            .filter((item: any) => item.slug && item.slug.includes('gift-card-')) // Filtra solo le customLineItems di tipo giftcard
            .map((item: any) => ({
                value: item.slug.split('gift-card-')[1],
                label: item.name[Object.keys(item.name)[0]],
                residual: Math.abs(item.money.centAmount)
            }));
        setSelectedGiftcards(selectedGiftCards);

        cart.lineItems.map((item: any) => {
            if (item?.productType?.obj?.key === "GIFTCARD") {
                setGiftcardInsideCart(true)

                if (item?.productType?.obj?.key === "GIFTCARD") {
                    let dataRecipient: any;
                    if (item?.custom?.fields['recipients-giftcard']) {
                        try {
                            dataRecipient = JSON.parse(item?.custom?.fields['recipients-giftcard']);
                            data["gift-card"][item.id] = dataRecipient
                        } catch (e) {}
                    }
                }
            }
        })
        reset(data)
    }, [])

    React.useEffect(() => {
        if (payU && payU.obj?.redirectUri) {
            redirectTo(payU.obj.redirectUri)
        }
    }, [payU])

    React.useEffect(() => {
        if (younitedUri && younitedUri?.redirectUri) {
            redirectTo(younitedUri?.redirectUri)
        }
    }, [younitedUri])

    React.useEffect(() => {
        if(payFastSignature !== ""){
            const cartTotal = (cart.totalPrice.centAmount / Math.pow(10, cart.totalPrice.fractionDigits)).toFixed(2);

            const form = document.createElement('form');
            form.method = 'post';
            form.action = PAYFAST_BASE_URL;
            form.style.display = 'none';

            const addField = (name: string, value: any) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = name;
                input.value = value;
                form.appendChild(input);
            };

            const returnUrl = `${PAYFAST_RETURN_URL}?pfcid=${cart.id}`
            addField('merchant_id', PAYFAST_MERCHANT_ID);
            addField('merchant_key', PAYFAST_MERCHANT_KEY);
            addField('return_url', returnUrl);
            addField('cancel_url', PAYFAST_CANCEL_URL);
            addField('notify_url', PAYFAST_NOTIFY_URL);
            addField('amount', cartTotal);
            addField('item_name', cart.id);
            addField('custom_str1', PROJECT_KEY)
            addField('signature', payFastSignature);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
            setClickedPay(false)
        }
    },[payFastSignature])

    React.useEffect(() => {
        if (buyBox && buyBox.obj?.redirectUri) {
            redirectTo(buyBox.obj.redirectUri)
        }
    }, [buyBox])

    //function for unmount all components for new radio item that shows button
    const unmountAllComponents = () => {
        unmountAdyenComponents()
        const younitedComponent = document.getElementById("younited-close");
        if (younitedComponent) {
            younitedComponent.click();
        }
    }

    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState('');
    const handlePaymentMethodChange = (method: string) => {
        setSelectedPaymentMethod(method);
        // Call the corresponding function based on the selected method
        switch (method) {
            case 'adyen':
                loadAdyenForm();
                break;
            case 'paypal-direct':
                loadPaypalDirectForm();
                break;
            case 'paypal':
                loadPaypalForm();
                break;
            case 'payu':
                unmountAllComponents();
                break;
            case 'ratypayu':
                unmountAllComponents();
                break;
            case 'banktransfer':
                unmountAllComponents();
                break;
            case 'payLaterWithPayByLink':
                unmountAllComponents();
                break;
            case 'klarna':
                loadKlarnaForm();
                break;
            case 'sepa':
                loadSepaForm();
                break;
            case 'bancontact':
                loadBancontactForm();
                break;
            case 'ideal':
                loadIdealForm();
                break;
            case 'applepay':
                loadApplePayForm();
                break;
            case 'buybox':
                unmountAllComponents()
                break;
            case 'payfast':
                unmountAllComponents();
                break;
            default:
                break;
        }
    };

    return (
        <div style={{position: "relative"}} className={`row no-gutters ${processingOrder && "processingOrderStyle"}`}>
            <div className="checkout-column-main w-100">
                <div className="wrapper">

                    {/* riepilogo shipping/billing address */}
                    <div className="column-thankyou">
                        {
                            selectedShippingAddress && (
                                <>
                                    <div className="component">
                                        <Box className={`secondary compact`}>
                                            <div className="font-weight-bold">{shippingAddressSectionTitle}</div>
                                            <span>
                                                <span>{selectedShippingAddress}</span> -{" "}
                                                <a href="#" className="font-weight-bold" onClick={(e) => {
                                                    e.preventDefault();
                                                    if(shippingType !== EShippingType.Pickup){
                                                        setIsAddressEdit(EAddressType.Shipping, true)
                                                    }
                                                    setIsAddressEdit(EAddressType.Billing, false)
                                                    setUseShippingAddressAsBilling(false)
                                                    setCheckoutStep(1)
                                                }}>
                                                    {t("ecommerce.checkout.edit-selected-address")}
                                                </a>
                                            </span>
                                        </Box>
                                    </div>
                                </>
                            )
                        }
                        {
                            selectedBillingAddress && (
                                <>
                                    <div className="component">
                                        <Box className={`secondary compact`}>
                                            <div className="font-weight-bold">{billingAddressSectionTitle}</div>
                                                <span>
                                                    <span>{selectedBillingAddress}</span> -{" "}
                                                    <a href="#" className="font-weight-bold" onClick={(e) => {
                                                        e.preventDefault();
                                                        setIsAddressEdit(EAddressType.Shipping, false)
                                                        setIsAddressEdit(EAddressType.Billing, true)
                                                        setUseShippingAddressAsBilling(false)
                                                        setCheckoutStep(1)
                                                    }}>
                                                        {t("ecommerce.checkout.edit-selected-address")}
                                                    </a>
                                                </span>
                                        </Box>
                                    </div>
                                </>
                            )
                        }
                    </div>


                    {/* Metodi di spedizione - Solo se consegna a casa  */}
                    {shippingType === EShippingType.Home &&
                    cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
                    cart.billingAddress && (
                        <div className="component">
                            <Box className={`secondary compact`}>
                                <h3>{t("ecommerce.checkout.shipping-section-title")}</h3>
                                <div>{t("ecommerce.checkout.shipping-section-description")}</div>
                                <ul>
                                    {shippingMethods
                                        ?.filter(
                                            (shippingMethod: any) =>
                                                shippingMethod.custom?.fields["shipping-type-list"] !== EShippingType.Pickup
                                        )
                                        .map((shippingMethod: any, key: number) => {
                                            let shippingName: string = shippingMethod?.localizedName?.[PROJECT_LANGUAGE] ? shippingMethod?.localizedName?.[PROJECT_LANGUAGE] : shippingMethod?.name;
                                            let description: string = shippingMethod?.localizedDescription?.[PROJECT_LANGUAGE]
                                            let shippingMethodPrice: ITotalPrice = shippingMethod?.zoneRates[0]?.shippingRates[0]?.price ? shippingMethod?.zoneRates[0]?.shippingRates[0]?.price : null;
                                            let taxedShippingPrice = cart.taxedShippingPrice?.totalGross?.centAmount || 0;
                                            if (shippingMethod?.zoneRates[0]?.shippingRates[0]?.freeAbove) {
                                                let freeAbove: boolean = (cart.totalPrice.centAmount - taxedShippingPrice) > shippingMethod?.zoneRates[0]?.shippingRates[0]?.freeAbove.centAmount
                                                if (freeAbove) shippingMethodPrice.centAmount = 0
                                            }
                                            return (
                                                <div key={key} className="radio-item w-100">
                                                    <div className="d-flex">
                                                        <div>
                                                            <input
                                                                id={shippingMethod.id}
                                                                value={shippingMethod.id}
                                                                type="radio"
                                                                checked={shippingMethod?.id === cart.shippingInfo?.shippingMethod?.id}
                                                                onChange={() => setShippingMethod(shippingMethod.id)}
                                                            />{" "}
                                                            <label htmlFor={shippingMethod.id}>{shippingName}</label>
                                                        </div>
                                                        {shippingMethodPrice &&
                                                        <div className="price">
                                                            <span className="px-1">-</span> 
                                                            {shippingMethodPrice?.centAmount > 0 ?
                                                                parsePrice(shippingMethodPrice)
                                                                : shippingMethodPrice?.centAmount === 0 ?
                                                                    t("ecommerce.checkout.free")
                                                                    : null
                                                            }
                                                        </div>
                                                        }
                                                    </div>
                                                    {description && <p className="text-sm pl-3">{description}</p>}
                                                </div>
                                            )
                                        })}
                                </ul>
                            </Box>
                        </div>
                    )
                    }

                    {/* Inserimento email destinatario per ogni buono regalo */}
                    {cart.billingAddress && cart.shippingInfo && cart.lineItems && giftcardInsideCard && IS_GIFT_CARD_ACTIVE &&
                    <>
                        <Box className={`secondary compact`}>
                            <h3 style={{marginBottom: '0'}}>{t("ecommerce.giftcard.checkout.form-section-title")}</h3>
                            <p style={{marginBottom: '20px'}}>{t("ecommerce.giftcard.checkout.form-section-subtitle")}</p>
                            <form action="" id="giftCardForm" onSubmit={handleSubmit(onSubmit)}>
                                {cart.lineItems.map((giftCard) => {
                                    if (giftCard?.productType?.obj?.key === "GIFTCARD") {
                                        const {name, quantity} = giftCard;

                                        return (
                                            <>
                                                {[...Array(quantity)].map((giftcardQta, giftCardQtaIndex) => {

                                                    return (
                                                        <>
                                                            <Box className="secondary unbordered unboxed ">
                                                                <Property
                                                                    label={t("ecommerce.giftcard.checkout.giftCard-title")}
                                                                    item={`${name[Object.keys(name)[0]]} - ${quantity >= 2 ? `${giftCardQtaIndex + 1} / ${quantity.toString()}` : quantity}`}
                                                                />
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <InputElement type='name'
                                                                                      data-Id={giftCard.id}
                                                                                      data-quantity={giftCardQtaIndex + 1}
                                                                                      data-type={"name"}
                                                                                      label={t("ecommerce.giftcard.name")}
                                                                                      key={name + giftCard.id + giftCardQtaIndex}
                                                                                      externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.name`)}
                                                                                      errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.name?.message}
                                                                                      disabled={enabledGiftCardEditButton}/>
                                                                    </div>
                                                                    <div className="col">
                                                                        <InputElement type='surname'
                                                                                      data-Id={giftCard.id}
                                                                                      data-quantity={giftCardQtaIndex + 1}
                                                                                      data-type={"surname"}
                                                                                      label={t("ecommerce.giftcard.surname")}
                                                                                      key={name + giftCard.id + giftCardQtaIndex}
                                                                                      externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.surname`)}
                                                                                      errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.surname?.message}
                                                                                      disabled={enabledGiftCardEditButton}/>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <InputElement type='email'
                                                                                      data-Id={giftCard.id}
                                                                                      data-quantity={giftCardQtaIndex + 1}
                                                                                      data-type={"email"}
                                                                                      label={t("ecommerce.giftcard.email")}
                                                                                      key={name + giftCard.id + giftCardQtaIndex}
                                                                                      externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.email`)}
                                                                                      errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.email?.message}
                                                                                      disabled={enabledGiftCardEditButton}/>
                                                                    </div>
                                                                    <div className="col">
                                                                        <InputElement type='confirmEmail'
                                                                                      data-Id={giftCard.id}
                                                                                      data-quantity={giftCardQtaIndex + 1}
                                                                                      data-type={"confirmEmail"}
                                                                                      label={t("ecommerce.giftcard.confirmEmail")}
                                                                                      key={name + giftCard.id + giftCardQtaIndex}
                                                                                      externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.confirmEmail`)}
                                                                                      errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.confirmEmail?.message}
                                                                                      disabled={enabledGiftCardEditButton}/>
                                                                    </div>
                                                                </div>
                                                            </Box>
                                                        </>)
                                                })}
                                            </>
                                        );
                                    }
                                })}
                                <>
                                    {!enabledGiftCardEditButton && <Button className="primary mt-4 mr-4" label={t("ecommerce.giftcard.checkout.save")} type="submit"/>}
                                    {enabledGiftCardEditButton && <Button className="primary mt-4" label={t("ecommerce.common.edit")} type="button" onClick={() => {setEnabledGiftCardEditButton(false)}}/>}
                                </>
                            </form>
                        </Box>
                    </>
                    }

                    {/* Inserimento nota su ordine */}
                    {!["DE", "NL"].includes(PROJECT_COUNTRY) &&
                    cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
                    cart.billingAddress &&
                    cart.shippingInfo && (
                        <div className="component">
                            <Box className={`secondary compact`}>
                                <h3>{t("ecommerce.checkout.note-title")}</h3>
                                <div>{t("ecommerce.checkout.note-description")}</div>
                                <textarea
                                    style={{width: "100%"}}
                                    className=""
                                    onBlur={(e) => {
                                        setNoteOnCart(e.target.value)
                                    }}
                                />
                            </Box>
                        </div>
                    )
                    }

                    {/* se ci sono giftcard da acquistare e il form dei destinatari é stato compilato o se non ci sono giftcard a carrello */}
                    {(enabledGiftCardEditButton || !giftcardInsideCard) &&
                    <>
                        <Box className="boxed-mobile">
                            <span className="text-bold">{t("ecommerce.checkout.deliveryInfo")}</span>
                        </Box>
                        {/* isCartChange = true se a carrello é presente un prodotto con disponibilitá negativa (caso particolare per POLONIA che puo avere prodotti con disponibilitá negativa*/}
                        {!isCartChanged &&
                        cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
                        cart.billingAddress &&
                        cart.shippingInfo && (
                            <div className="component">
                                <Box className="secondary compact">

                                    <h3>{t("ecommerce.checkout.payments-methods-section-title")}</h3>

                                    {IS_GIFT_CARD_ACTIVE && hasGiftCards &&
                                    <>
                                        <div className="checkboxButton">
                                            <input
                                                type="checkbox"
                                                id="toggleDropdown"
                                                name="toggleDropdown"
                                                checked={showGiftCardSelect}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setShowGiftCardSelect(isChecked);

                                                    if (!isChecked) {
                                                        setRemoveAllGiftCardFromCart(cart);
                                                    }
                                                }}
                                            />
                                            <label htmlFor="toggleDropdown">{t("ecommerce.giftcard.load")}</label>
                                        </div>
                                        {showGiftCardSelect &&
                                            <SelectMultiOptions onChange={handleGiftCardSelectOnChange} isMulti options={options} value={selectedGiftcards} className={"col-12 mb-3"}/>
                                        }
                                    </>
                                    }

                                    {cart.totalPrice.centAmount > 0 &&
                                    <>
                                        <div className="">
                                            {PAYMENT_METHODS?.includes("adyen") &&
                                            <PaymentMethodDiv className="container-payment-methods">
                                                <div className="radio-item w-100">
                                                    <input
                                                        type="radio"
                                                        id="adyen-checkbox"
                                                        name="paymentMethod"
                                                        value="adyen"
                                                        checked={selectedPaymentMethod === "adyen"}
                                                        onClick={() => {handlePaymentMethodChange("adyen")}}
                                                    />
                                                    <label htmlFor="adyen-checkbox" className="btn-payment-methods icon-cards-visa-mastercard">
                                                        {t("ecommerce.checkout.pay-button")}
                                                        {CHECKOUT_PAYMENT_LOGOS?.adyen?.map((logo: string, i: number) => (
                                                            <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                        ))}
                                                    </label>
                                                </div>

                                                <div id="adyen"/>

                                            </PaymentMethodDiv>
                                            }
                                            {PAYMENT_METHODS?.includes("paypal-direct") &&
                                            <PaymentMethodDiv className="container-payment-methods">
                                                <div className="radio-item">
                                                    <input
                                                        type="radio"
                                                        id="paypal-direct-checkbox"
                                                        name="paymentMethod"
                                                        value="paypal-direct"
                                                        checked={selectedPaymentMethod === "paypal-direct"}
                                                        onChange={() => handlePaymentMethodChange("paypal-direct")}
                                                    />
                                                    <label htmlFor="paypal-direct-checkbox" className="btn-payment-methods icon-cards-paypal">
                                                        {t("ecommerce.checkout.paypal.direct")}
                                                        <img className={"payment-icon pl-3 mb-1"} src={`${BRX_ASSETS_URL}/images/icons/paymentMethods/paypal.svg`} alt={"paypal"} />
                                                    </label>
                                                </div>

                                                {loadingPaypal && showPaypal && <div className="isLoading"/>}

                                                {showPaypal &&
                                                <div id="paypal-direct-container" style={{display:'none'}} className="text-center">
                                                    <div id="card-name-field-container"/>
                                                    <div id="card-number-field-container"/>
                                                    <div id="card-expiry-field-container"/>
                                                    <div id="card-cvv-field-container"/>
                                                    <p className="w-100 error message--alert">{paypalError}</p>
                                                    <Button
                                                        id="card-field-submit-button"
                                                        className="ec_btn_primary"
                                                        type="button"
                                                        icon={"fas fa-circle-notch fa-spin"}
                                                        loader={{ startLoader: clickedPayWithPaypal, stopLoader: !clickedPayWithPaypal }}
                                                        disabled={clickedPayWithPaypal}
                                                        label={t("ecommerce.checkout.paypal.paynow")}>
                                                    </Button>
                                                </div>}

                                            </PaymentMethodDiv>
                                            }
                                            {PAYMENT_METHODS?.includes("paypal") &&
                                            <PaymentMethodDiv className="container-payment-methods">
                                                <div className="radio-item w-100">
                                                    <input
                                                        type="radio"
                                                        id="paypal-checkbox"
                                                        name="paymentMethod"
                                                        value="paypal"
                                                        checked={selectedPaymentMethod === "paypal"}
                                                        onChange={() => handlePaymentMethodChange("paypal")}
                                                    />
                                                    <label htmlFor="paypal-checkbox" className="btn-payment-methods icon-cards-paypal">
                                                        {t("ecommerce.checkout.paypal")}
                                                        {CHECKOUT_PAYMENT_LOGOS?.paypal?.map((logo: string, i: number) => (
                                                            <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                        ))}
                                                    </label>
                                                </div>


                                                <div id="paypal-container" className="text-center"/>

                                            </PaymentMethodDiv>
                                            }
                                            {PAYMENT_METHODS?.includes("payu") && !lineItemWithNoAvailability &&
                                            <PaymentMethodDiv className="container-payment-methods">
                                                <div className="radio-item w-100">
                                                    <input
                                                        type="radio"
                                                        id="payu-checkbox"
                                                        name="paymentMethod"
                                                        value="payu"
                                                        checked={selectedPaymentMethod === "payu"}
                                                        onChange={() => handlePaymentMethodChange("payu")}
                                                    />
                                                    <label htmlFor="payu-checkbox" className="btn-payment-methods icon-cards-payU">
                                                        {t("ecommerce.checkout.payu")}
                                                        {CHECKOUT_PAYMENT_LOGOS?.payu?.map((logo: string, i: number) => (
                                                            <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                        ))}
                                                   </label>
                                                </div>
                                                {selectedPaymentMethod === "payu" && (
                                                    <div className="row text-center mt-3">
                                                        <div className="col">
                                                            <Button
                                                                disabled={true}
                                                                className="ec_btn_primary icon-cards-payU"
                                                                label={t("ecommerce.checkout.payu")}
                                                                onClick={() => redirectToPayU(false)} />
                                                        </div>
                                                    </div>
                                                )}
                                            </PaymentMethodDiv>
                                            }
                                            {PAYMENT_METHODS?.includes("payu") && !lineItemWithNoAvailability && cart.totalPrice.centAmount >= 30000 && cart.totalPrice.centAmount <= 5000000 &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <input
                                                            type="radio"
                                                            id="ratypayu-checkbox"
                                                            name="paymentMethod"
                                                            value="ratypayu"
                                                            checked={selectedPaymentMethod === "ratypayu"}
                                                            onChange={() => handlePaymentMethodChange("ratypayu")}
                                                        />
                                                        <label htmlFor="ratypayu-checkbox" className="btn-payment-methods icon-cards-payU">
                                                            {t("ecommerce.checkout.ratypayu")}
                                                            {CHECKOUT_PAYMENT_LOGOS?.payu?.map((logo: string, i: number) => (
                                                                <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                            ))}
                                                        </label>
                                                    </div>
                                                    {selectedPaymentMethod === "ratypayu" && (
                                                        <div className="row text-center mt-3">
                                                            <div className="col">
                                                                <Button
                                                                    disabled={true}
                                                                    className="ec_btn_primary icon-cards-payU"
                                                                    label={t("ecommerce.checkout.ratypayu")}
                                                                    onClick={() => redirectToPayU(true)} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </PaymentMethodDiv>
                                            }
                                            {PROJECT_COUNTRY?.includes("PL") && !lineItemWithNoAvailability &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <input
                                                            type="radio"
                                                            id="banktransfer-checkbox"
                                                            name="paymentMethod"
                                                            value="banktransfer"
                                                            checked={selectedPaymentMethod === "banktransfer"}
                                                            onChange={() => handlePaymentMethodChange("banktransfer")}
                                                        />
                                                        <label htmlFor="banktransfer-checkbox" className="btn-payment-methods icon-cards-banktransfer">
                                                            {t("ecommerce.checkout.banktransfer")}
                                                            {CHECKOUT_PAYMENT_LOGOS?.banktransfer?.map((logo: string, i: number) => (
                                                                <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                            ))}
                                                        </label>
                                                    </div>

                                                    {selectedPaymentMethod === "banktransfer" && (
                                                        <div className="row text-center mt-3">
                                                            <div className="col">
                                                                <Button
                                                                    disabled={true}
                                                                    className="ec_btn_primary icon-cards-bankTrans"
                                                                    label={t("ecommerce.checkout.banktransfer")}
                                                                    onClick={payWithBankTransfer} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </PaymentMethodDiv>
                                            }
                                            {PROJECT_COUNTRY?.includes("PL") && lineItemWithNoAvailability &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <input
                                                            type="radio"
                                                            id="payLaterWithPayByLink-checkbox"
                                                            name="paymentMethod"
                                                            value="payLaterWithPayByLink"
                                                            checked={selectedPaymentMethod === "payLaterWithPayByLink"}
                                                            onChange={() => handlePaymentMethodChange("payLaterWithPayByLink")}
                                                        />
                                                        <label htmlFor="payLaterWithPayByLink-checkbox" className="btn-payment-methods icon-cards-payLaterWithPayByLink">
                                                            {t("ecommerce.checkout.payLaterWithPayByLink")}
                                                            {/* <img className={"payment-icon pl-3 mb-1"} src={`${BRX_ASSETS_URL}/images/icons/paymentMethods/payLater.svg`} alt={"payLater"} /> */}
                                                        </label>
                                                    </div>
                                                    {selectedPaymentMethod === "payLaterWithPayByLink" && (
                                                        <div className="row text-center mt-3">
                                                            <div className="col">
                                                                <Button
                                                                    disabled={true}
                                                                    className="ec_btn_primary icon-cards-payByLink"
                                                                    label={t("ecommerce.checkout.payLaterWithPayByLink")}
                                                                    onClick={payLaterWithPayByLink} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </PaymentMethodDiv>
                                            }
                                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("klarna_account") !== -1 && cart.totalPrice.centAmount < 300000 &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <input
                                                            type="radio"
                                                            id="klarna-checkbox"
                                                            name="paymentMethod"
                                                            value="klarna"
                                                            checked={selectedPaymentMethod === "klarna"}
                                                            onChange={() => handlePaymentMethodChange("klarna")}
                                                        />
                                                        <label htmlFor="klarna-checkbox" className="btn-payment-methods icon-cards-klarna">
                                                            {t("ecommerce.checkout.klarna")}
                                                            {CHECKOUT_PAYMENT_LOGOS?.klarna_account?.map((logo: string, i: number) => (
                                                                <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                            ))}
                                                        </label>
                                                    </div>

                                                    <div id="klarna-container"/>

                                                </PaymentMethodDiv>
                                            }
                                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("sepadirectdebit") !== -1 &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <RadioInput
                                                            type="radio"
                                                            id="sepa-checkbox"
                                                            name="paymentMethod"
                                                            value="sepa"
                                                            checked={selectedPaymentMethod === "sepa"}
                                                            onChange={() => handlePaymentMethodChange("sepa")}
                                                        />
                                                        <label htmlFor="sepa-checkbox" className="btn-payment-methods icon-cards-sepa">
                                                            {t("ecommerce.checkout.sepa")}
                                                            <img className={"payment-icon pl-3 mb-1"} src={`${BRX_ASSETS_URL}/images/icons/paymentMethods/sepa.svg`} alt={"sepa"} />
                                                        </label>
                                                    </div>

                                                    <div id="sepadirectdebit"/>

                                                </PaymentMethodDiv>
                                            }
                                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("bancontact") !== -1 &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <RadioInput
                                                            type="radio"
                                                            id="bancontact-checkbox"
                                                            name="paymentMethod"
                                                            value="bancontact"
                                                            checked={selectedPaymentMethod === "bancontact"}
                                                            onChange={() => handlePaymentMethodChange("bancontact")}
                                                        />
                                                        <label htmlFor="bancontact-checkbox" className="btn-payment-methods icon-cards-bancontact">
                                                            {t("ecommerce.checkout.bancontact")}
                                                            {CHECKOUT_PAYMENT_LOGOS?.bancontact?.map((logo: string, i: number) => (
                                                                <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                            ))}
                                                        </label>
                                                    </div>

                                                    <div id="bcmc-container"/>

                                                </PaymentMethodDiv>
                                            }
                                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("ideal") !== -1 &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <input
                                                            type="radio"
                                                            id="ideal-checkbox"
                                                            name="paymentMethod"
                                                            value="ideal"
                                                            checked={selectedPaymentMethod === "ideal"}
                                                            onChange={() => handlePaymentMethodChange("ideal")}
                                                        />
                                                        <label htmlFor="ideal-checkbox" className="btn-payment-methods icon-cards-ideal">
                                                            {t("ecommerce.checkout.ideal")}
                                                            {CHECKOUT_PAYMENT_LOGOS?.ideal?.map((logo: string, i: number) => (
                                                                <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                            ))}
                                                        </label>
                                                    </div>

                                                    <div id="ideal-container"/>

                                                </PaymentMethodDiv>
                                            }
                                            {ADYEN_ENABLEDPAYMENTMETHODS.indexOf("applepay") !== -1 &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <RadioInput
                                                            type="radio"
                                                            id="applepay-checkbox"
                                                            name="paymentMethod"
                                                            value="applepay"
                                                            checked={selectedPaymentMethod === "applepay"}
                                                            onChange={() => handlePaymentMethodChange("applepay")}
                                                        />
                                                        <label htmlFor="applepay-checkbox" className="btn-payment-methods icon-cards-applepay">
                                                            {t("ecommerce.checkout.applepay")}
                                                            {CHECKOUT_PAYMENT_LOGOS?.applepay?.map((logo: string, i: number) => (
                                                                <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                            ))}
                                                        </label>
                                                    </div>

                                                    <div id="applepay-container"/>
                                                    {applepayEnabled && (
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: t("ecommerce.error.applePayDisabled") }}
                                                            className="w-100 error message--alert small"
                                                        />
                                                    )}
                                                </PaymentMethodDiv>
                                            }
                                            {PAYMENT_METHODS?.includes("buybox") &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <input
                                                            type="radio"
                                                            id="buybox-checkbox"
                                                            name="paymentMethod"
                                                            value="buybox"
                                                            checked={selectedPaymentMethod === "buybox"}
                                                            onChange={() => handlePaymentMethodChange("buybox")}
                                                        />
                                                        <label htmlFor="buybox-checkbox" className="btn-payment-methods icon-cards-buybox">
                                                            {t("ecommerce.checkout.buybox")}
                                                            <img className={"payment-icon pl-3 mb-1"} src={`${BRX_ASSETS_URL}/images/icons/paymentMethods/buybox.svg`} alt={"buybox"} />
                                                        </label>
                                                    </div>
                                                    {selectedPaymentMethod === "buybox" && (
                                                        <div className="row text-center mt-3">
                                                            <div className="col">
                                                                <Button
                                                                    disabled={true}
                                                                    className="ec_btn_primary icon-cards-buyBox"
                                                                    label={t("ecommerce.checkout.buybox")}
                                                                    onClick={redirectToBuyBox} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </PaymentMethodDiv>
                                            }
                                            {PAYMENT_METHODS?.includes("younited") && cart.totalPrice.centAmount >= 30000 &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    {
                                                        <YounitedPay
                                                            cartid={cart.id}
                                                            amount={(cart.totalPrice.centAmount / 100)}
                                                            redirectToYounitedPay={redirectToYounitedPay}
                                                            showPayButton={true}
                                                            setInModal={false}
                                                            setClickedPay={setClickedPay}
                                                            unmountAdyenComponents={unmountAdyenComponents}
                                                            phone={cart.billingAddress?.mobile?.toString()}
                                                            selectedPaymentMethod={selectedPaymentMethod}
                                                            setSelectedPaymentMethod={setSelectedPaymentMethod}
                                                        />
                                                    }
                                                </PaymentMethodDiv>
                                            }
                                            {PAYMENT_METHODS?.includes("payfast") &&
                                                <PaymentMethodDiv className="container-payment-methods">
                                                    <div className="radio-item w-100">
                                                        <input
                                                            type="radio"
                                                            id="payfast-checkbox"
                                                            name="paymentMethod"
                                                            value="payfast"
                                                            checked={selectedPaymentMethod === "payfast"}
                                                            onChange={() => handlePaymentMethodChange("payfast")}
                                                        />
                                                        <label htmlFor="payfast-checkbox" className="btn-payment-methods icon-cards-payfast">
                                                            {t("ecommerce.checkout.payfast")}
                                                            {CHECKOUT_PAYMENT_LOGOS?.payfast?.map((logo: string, i: number) => (
                                                                <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                            ))}
                                                        </label>
                                                    </div>
                                                    {selectedPaymentMethod === "payfast" && (
                                                        <div className="row text-center mt-3">
                                                            <div className="col">
                                                                <Button
                                                                    btn_disabled={clickedPay}
                                                                    icon={"fas fa-circle-notch fa-spin"}
                                                                    className="ec_btn_primary icon-cards-payfast"
                                                                    label={t("ecommerce.checkout.payfast")}
                                                                    onClick={redirectToPayFast}
                                                                    loader={{ startLoader: clickedPay, stopLoader: !clickedPay }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </PaymentMethodDiv>
                                            }
                                        </div>

                                        <div className="row" id="payments-row">
                                            <div className="col-md-8 offset-md-2">
                                                <div id="action-container"/>
                                            </div>
                                        </div>
                                    </>
                                    }

                                    {cart.totalPrice.centAmount === 0 && cart.customLineItems && cart.customLineItems.some(item => item.slug.startsWith('gift-card')) &&
                                    <div className="text-center">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Button
                                                    disabled={true}
                                                    className="ec_btn_primary"
                                                    label={t("ecommerce.checkout.closeOrder")}
                                                    onClick={closeOrderWithGiftCard} />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </Box>
                            </div>
                        )}
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

const AddressList = (props: IAddressList) => {
    const {type, shippingType, className} = props
    const {userData, cart, shippingMethods, invoiceValue, setInvoiceValue} = React.useContext(GeneralContext)
    const {setAddress, setSendInvoice, setPickupInStore, selectedShippingMethod} = React.useContext(CartContext)
    const {addAddressFormVisibility, setAddAddressFormVisibility,
        useShippingAddressAsBilling, numberAddressAvailable,
        setNumberAddressAvailable, selectedAddress,
        setSelectedAddress, isAddressEdit, setShowListAddress,
        setIsAddressEdit, setUseShippingAddressAsBilling} = React.useContext(CheckoutContext)
    const componentElement = React.useRef<HTMLDivElement>(null)
    const [hasExecuted, setHasExecuted] = React.useState(false)
    const [addressFormatted, setAddressFormatted] = React.useState<string | null>(null)
    const [listAddress, setListAddress] = React.useState<any>(null!)

    /* Mostra la Form di inserimento indirizzo */

    const {t} = React.useContext(LabelContext)

    React.useEffect(() => {
        if (BRX_LOCALE === "nl_NL") {
            setInvoiceValue(true)
        }
    }, [])

    React.useEffect(() => {
        /*
        Indirizzi disponibili per la selezione
        Se utente loggato:
          - Conta gli indirizzi del tipo selezionato
        Se guest:
          - Conta gli indirizzi nel carrello (sempre uno) ma solo con internalType === undefined (per escludere internalType === "placeholder")
        */

        let listAddress: any = [];

        if (userData) {
            const selectedAddressIds = userData[EUserDataAddressesIdentifier[type]];
            listAddress = userData.addresses.filter((address) =>
                selectedAddressIds.includes(address.id)
            );
        } else {
            const selectedCartAddress = cart[ESelectedAddress[type]];
            if (selectedCartAddress && !selectedCartAddress.custom?.fields?.internalType) {
                listAddress = [selectedCartAddress];
            }
        }

        setListAddress(listAddress);
        setNumberAddressAvailable(type, listAddress.length);
    }, [cart])

    /*
    Indica che è possibile aggiunge un nuovo indirizo:
    - SI: se indirizzo di fatturazione
    - SI: se indirizzo di spedizione con Ritiro a casa
    - NO: se indirizzo di spedizione con Ritiro in STORE
     */
    const canAddNewAddress =
        (type === EAddressType.Shipping && shippingType === EShippingType.Home) || type === EAddressType.Billing

    const useStoresAsAddress = type === EAddressType.Shipping && shippingType === EShippingType.Pickup

    // Titolo della sezione
    let title: string =
        (type === EAddressType.Shipping
            ? shippingType === EShippingType.Home
                ? t("ecommerce.checkout.home-address-title")
                : t("ecommerce.checkout.select-store-title")
            : t("ecommerce.checkout.billing-address-title")) || ""

    // Sottotitolo della sezione
    let subTitle: string =
        (type === EAddressType.Shipping
            ? shippingType === EShippingType.Home
                ? t("ecommerce.checkout.home-address-description")
                : t("ecommerce.checkout.select-store-description")
            : t("ecommerce.checkout.billing-address-description")) || ""

    /*
    Testo del pulsante Aggiungi indirizzo:
    Suddiviso per Loggato | Gest - Spedizione | Fatturazione
    */
    let labelButtonAddNewAddress: string = userData
        ? t("ecommerce.checkout.button-new-billing-address-logged-user")
        : t("ecommerce.checkout.button-new-billing-address-guest")
    if (type === EAddressType.Shipping) {
        labelButtonAddNewAddress = userData
            ? t("ecommerce.checkout.button-new-shipping-address-logged-user")
            : t("ecommerce.checkout.button-new-shipping-address-guest")
    }

    React.useEffect(() => {
        let address = cart[ESelectedAddress[type]]
        let addressFormatted = null
        if (address && address?.custom?.fields?.internalType !== "placeholder") {
            addressFormatted =
                type === "billing"
                    ? address.custom?.fields?.fiscalcode
                    ? address.custom?.fields?.fiscalcode
                    : address.company
                        ? address.company
                        : ""
                    : ""
            addressFormatted =
                addressFormatted +
                " " +
                address.firstName +
                " " +
                (address.lastName ? address.lastName : "") +
                ", " +
                address.streetName +
                " " +
                (address.streetNumber !== undefined ? address.streetNumber : "") +
                ", " +
                address.postalCode +
                " " +
                address.city
            setSelectedAddress(type, address)
        }
        setAddressFormatted(addressFormatted)
    },[type, cart])

    React.useEffect(() => {
        // Se non sono presenti indirizzi ed è possile aggiungerne, aprima la form
        // guest + no indirizzi = indirizzi esplosi
        // guest + inidirizzi = indirizzi esplosi
        // loggato + indirizzi = inidirizzi non esplosi
        // loggato + no indirizzi = indirizzi esplosi
        if (!hasExecuted) {
            if(type === EAddressType.Shipping){
                setAddAddressFormVisibility(type, (numberAddressAvailable.shipping === 0 || (!userData && numberAddressAvailable.shipping > 0 && isAddressEdit.shipping) || (userData && numberAddressAvailable.shipping > 0 && isAddressEdit.shipping)) && canAddNewAddress)
                // Se non c'è un indirizzo di spedizione selezionato mostriamo lista
                setShowListAddress(EAddressType.Shipping, !selectedAddress.shipping)
                //loggato + indirizzi = eseguilo solo una volta (problema se una volta aperto usi il flag useShippingAddressAsBilling)
                if(numberAddressAvailable.shipping > 0){
                    setHasExecuted(true)
                }
            }
            if(type === EAddressType.Billing) {
                setAddAddressFormVisibility(type, (numberAddressAvailable.billing === 0 || (!userData && numberAddressAvailable.billing > 0 && isAddressEdit.billing) || (userData && numberAddressAvailable.billing > 0 && isAddressEdit.billing)) && canAddNewAddress && (!useShippingAddressAsBilling || isAddressEdit.billing))
                // Se non c'è un indirizzo di fatturazione selezionato mostriamo lista
                setShowListAddress(EAddressType.Billing, !selectedAddress.billing)
                //loggato + indirizzi = eseguilo solo una volta (problema se una volta aperto usi il flag useShippingAddressAsBilling)
                if(numberAddressAvailable.billing > 0){
                    setHasExecuted(true)
                }
            }

        }

    }, [hasExecuted, numberAddressAvailable, canAddNewAddress, addressFormatted, useShippingAddressAsBilling])

    //nascondo titolo intestazione e link aggiunta indirizzo quando non ci sono indirizzi e il form shipping é esploso con useShippingAsBilling a false
    let hideInfo = false
    if (numberAddressAvailable.billing === 0 && useShippingAddressAsBilling){
        hideInfo = true
    }

    return (
        <div className="component" ref={componentElement}>
            {(!useStoresAsAddress ? listAddress?.length>0 : shippingMethods?.length>0) && (
                <Box className={`secondary compact ${className}`}>
                    {!hideInfo && <h4 className="mb-0">{title}</h4>}

                    {/*todo: commentato temporaneamente {numberAddressAvailable[type] > 0 && userData && showListAddress[type] && <div>{subTitle}</div>}*/}

                    <ul>
                        {!useStoresAsAddress
                            ? listAddress &&
                            numberAddressAvailable[type] > 0 &&
                            listAddress.map((address: any, i: number) => {
                                const isSelected = selectedAddress[type]?.id === address.id
                                return (
                                    <div className="row">
                                        <div className="col-8 col-md-10">
                                            <div key={i} className="radio-item">
                                                <input
                                                    id={`address-${type}-${i}`}
                                                    type="radio"
                                                    name={`address-${type}`}
                                                    defaultChecked={isSelected}
                                                    onClick={() => {
                                                        setSelectedAddress(type, address)
                                                        setAddress(address, type).subscribe()
                                                        setAddAddressFormVisibility(type, false)
                                                        setShowListAddress(type, false)
                                                    }}
                                                />
                                                <label htmlFor={`address-${type}-${i}`}>
                                                    <div>
                                                        {type === "billing"
                                                            ? address.custom?.fields?.fiscalcode
                                                                ? " " + address.custom?.fields?.fiscalcode
                                                                : " " + (address.company ? address.company : "")
                                                            : ""}{" "}
                                                        {address.firstName} {address.lastName}, {address.streetName}{" "}
                                                        {address.streetNumber !== undefined ? address.streetNumber : ""}, {address.postalCode}{" "}
                                                        {address.city}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {isSelected &&
                                            <div className="col-4 col-md-2">
                                                <Button btn_disabled={addAddressFormVisibility[type]} label={t("ecommerce.common.edit")} className="simple actionForm font-weight-bold text-reset" icon="fas fa-pencil-alt" onClick={() => {
                                                    setSelectedAddress(type, address)
                                                    setIsAddressEdit(type, true)
                                                    setAddAddressFormVisibility(type, true)
                                                    //se ho entrambi gli indirizzi e il flag é a true e decido di modiicare il billing. il flag non permette di salvarlo perche salva lo shipping come billing a causa del flag
                                                    setUseShippingAddressAsBilling(false)
                                                }}/>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                            : (shippingMethods || [])
                                .filter(
                                    (store: any) =>
                                        store.custom?.fields?.["shipping-type-list"] &&
                                        store.custom.fields["shipping-type-list"] === EShippingType.Pickup
                                )
                                .map((address: any, i: number) => {
                                    const isSelected = selectedShippingMethod?.id === address.id
                                    return (
                                        <>
                                            <div key={i} className="radio-item">
                                                <input
                                                    id={"shippingMethod-" + i}
                                                    type="radio"
                                                    name="shippingMethod"
                                                    defaultChecked={isSelected}
                                                    onClick={() => {
                                                        setPickupInStore(address.id)
                                                        setAddAddressFormVisibility(type, false)
                                                        setShowListAddress(type,false)
                                                    }}
                                                />
                                                <label htmlFor={"shippingMethod-" + i}>
                                                    <div>
                                                        <b>{address.name}</b>, {address.custom.fields.streetName} {address.custom.fields.streetNumber},{" "}
                                                        {address.custom.fields.city}, {address.custom.fields.postalCode} ({address.custom.fields.region})
                                                    </div>
                                                </label>
                                            </div>
                                            <label style={{display: "block", marginLeft: "20px"}}>{address.localizedDescription?.[PROJECT_LANGUAGE]}</label>
                                        </>
                                    )
                                })
                        }
                    </ul>

                    {PROJECT_SETTINGS.showRequestInvoice === true && type === "billing" &&
                    <>
                        {!["DE", "PL"].includes(PROJECT_COUNTRY) &&
                            <div className="checkboxButton mb-3">
                                <input
                                    type="checkbox"
                                    value="1"
                                    name="saveInvoice"
                                    id="saveInvoice"
                                    checked={invoiceValue}
                                    onClick={(event: any) => {
                                        setSendInvoice(!invoiceValue);
                                        setInvoiceValue(event.target?.checked)
                                    }}
                                />{" "}
                                <label htmlFor="saveInvoice">{t("ecommerce.checkout.send-me-invoice-flag")}</label>
                            </div>
                        }
                        {["PL"].includes(PROJECT_COUNTRY) &&
                            <div className="checkboxButton mb-3" style={{ display: 'none' }}>
                                <input
                                    type="checkbox"
                                    value="1"
                                    name="saveInvoice"
                                    id="saveInvoice"
                                    checked={invoiceValue}
                                />{" "}
                                <label htmlFor="saveInvoice">{t("ecommerce.checkout.send-me-invoice-flag")}</label>
                            </div>
                        }
                    </>
                    }

                    {canAddNewAddress && ((type === EAddressType.Billing && !addAddressFormVisibility.billing) || (type === EAddressType.Shipping && !addAddressFormVisibility.shipping)) && !hideInfo && (
                        <>
                            {" "}
                            <button className="btn-outline-checkout mb-4"
                                onClick={() => {
                                    if(type === EAddressType.Billing){
                                        setIsAddressEdit(EAddressType.Billing, false)
                                        setAddAddressFormVisibility(EAddressType.Billing, true)
                                    } else {
                                        setIsAddressEdit(EAddressType.Shipping, false)
                                        setAddAddressFormVisibility(EAddressType.Shipping, true)
                                    }
                                }}
                            >{labelButtonAddNewAddress}</button>
                        </>
                    )}
                </Box>
            )}
        </div>
    )
}